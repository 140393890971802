import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import removeItems from '../../assets/removeItems.svg';
import plus from '../../assets/addItems.svg';
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { formatPrecio } from '../../hooks/useFormat';
import { useShopingCart } from '../../hooks/useShopingCart';
import { format } from '../../../../helpers/formatter';
import '../cart/Carro.css';
import 'animate.css';

export const RowsItems = ({ item }) => {
  const {
    handleDiscountProduct,
    handleIncreaseProduct,
    handleChangeProduct,
    handleDeleteProduct,
    currencyCart,
    handleDiscountChange,
  } = useShopingCart();

  const [showDialog, setShowDialog] = useState(false); // Controla el diálogo
  const [montoNetoCDcto, setMontoNetoCDcto] = useState(null); // Monto neto con descuento
  const [precioMinimo, setPrecioMinimo] = useState(null); // Precio mínimo
  const [discountValue, setDiscountValue] = useState(
    item.precios.descuento || 0
  ); // Valor del descuento

  const handleShowDialog = (montoNetoCDcto, precioMinimo) => {
    setMontoNetoCDcto(montoNetoCDcto);
    setPrecioMinimo(precioMinimo);
    setShowDialog(true);
  };

  const handleInputChange = async (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, ''); // Elimina caracteres no numéricos
    setDiscountValue(newValue); // Actualiza el estado con el nuevo valor del input
    const { precioExcedido, montoNetoCDcto } = handleDiscountChange(item)(e);

    if (precioExcedido) {
      handleShowDialog(montoNetoCDcto, item.precios.precio_minimo);
    } else {
      setShowDialog(false); // Oculta el dialog si no se excede el precio mínimo
    }
  };

  return (
    <article className="contenedorCardCarrito" key={item.codigo_mg}>
      <section className="cardCarrito">
        <h2 className="tituloCard contenedorTituloBtn">{item.denominacion}</h2>
        <button
          type="button"
          className="btnEliminarCard"
          onClick={handleDeleteProduct(item)}
        >
          <FontAwesomeIcon icon={faTrash} />
          Eliminar
        </button>

        <div className="contenedorDescuento">
          <span className="carritoDescuento">Descuento</span>
          <input
            className="selectordesc"
            value={discountValue} // Usar el estado como valor
            onChange={handleInputChange} // Maneja los cambios de input
            placeholder="0"
            onKeyDown={(e) => {
              const allowedKeys = [
                'Backspace',
                'Tab',
                'ArrowUp',
                'ArrowDown',
                'ArrowLeft',
                'ArrowRight',
              ];
              if (!e.key.match(/[0-9]/) && !allowedKeys.includes(e.key)) {
                // Restringimos el input a números
                e.preventDefault();
              }
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
          />
          <span>%</span>
        </div>

        <div className="contSelectorTot">
          <section className="selectorCant">
            <button
              type="button"
              className="selectorItems"
              onClick={handleDiscountProduct(item)}
            >
              <img src={removeItems} alt="Quita Items" />
            </button>
            <input
              className="selectorItems"
              onChange={handleChangeProduct(item)}
              value={item.cantidad}
            />
            <button
              type="button"
              className="selectorItems"
              onClick={handleIncreaseProduct(item)}
            >
              <img src={plus} alt="Agrega Items" />
            </button>
          </section>
        </div>

        <div className="selectorTotal">
          {discountValue > 0 && (
            <div className="handleTotDesc">
              <span className="CantDesc">-{discountValue}%</span>
              <span className="totSinDesc">
                {format.currency(
                  currencyCart,
                  formatPrecio(
                    item?.precios?.monto_con_iva_con_descuento,
                    item.cantidad || 0
                  )
                )}
              </span>
            </div>
          )}
          <h2 className="totalItems">
            {format.currency(
              currencyCart,
              formatPrecio(item?.precios?.monto_con_iva, item.cantidad || 0)
            )}
          </h2>
        </div>
      </section>

      {showDialog && (
        <div className="animate__animated animate__zoomIn alert">
          <FontAwesomeIcon
            className="warningIcon"
            icon={faTriangleExclamation}
          />
          <span className="alertText">
            Se excedió el precio mínimo. Monto:{' '}
            {format.currency(currencyCart, montoNetoCDcto)}, Precio mínimo:{' '}
            {format.currency(currencyCart, precioMinimo)}
          </span>
        </div>
      )}
    </article>
  );
};
