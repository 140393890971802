import { SendBuy } from '../../services/SendBuy';
import './Resume.css';
import '../cart/Carro.css';
import { useShopingCart } from '../../hooks/useShopingCart';
import ComprasAnteriores from '../purchases/comprasAnteriores';
import { useContext, useState } from 'react';
import { UserContext } from '../../../auth/context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../../../catalogo/hooks/useClient';
import { ResumeCard } from '../resumeCard/ResumeCard';

export const Resume = () => {
  const { getItems, clearItems } = useShopingCart();
  const { dataUser } = useContext(UserContext);
  const { data: dataClient } = useClient();
  const navigate = useNavigate();

  const { id: clientId } = dataClient || {};

  const eliminarItemDelCarrito = () => {
    clearItems();
    navigate('/catalog/catalogo');
  };

  const [codigoComprobante, setCodigoComprobante] = useState(1);

  // const handleCheckboxChange = (event) => {
  //   setCodigoComprobante(event.target.checked ? 2 : 1);
  // };

  const handleCheckboxChange = (event) => {
    setCodigoComprobante(Number(event.target.value));
  };

  const items = getItems().filter((item) => item.cantidad > 0);
  //ARMADO DE PEDIDO
  const productos = items.map(({ id, cantidad, precios, moneda }) => ({
    id: id,
    cantidad: cantidad,
    precio: precios,
    moneda: moneda,
  }));

  const pedido = [
    {
      clientId,
      user: dataUser.id,
      products: productos,
      codigo_comprobante: codigoComprobante,
    },
  ];
  // const titulo =
  //   codigoComprobante === 1 ? 'Resumen del Pedido' : 'Resumen del Presupuesto';
  return (
    <div className="cardResume">
      <article className="contenedorCardTotal sticky-footer">
        {/* <div className="contenedorLabelTotal">
          <label>
            <input
              type="radio"
              name="comprobante"
              value="2"
              checked={codigoComprobante === 2}
              onChange={handleCheckboxChange}
            />
            Presupuesto
          </label>
          <label>
            <input
              type="radio"
              name="comprobante"
              value="1"
              checked={codigoComprobante === 1}
              onChange={handleCheckboxChange}
            />
            Pedido
          </label>
        </div> */}
        <header>
          {/* <h2 className="tituloResCompra">{titulo}</h2> */}
          <h2>Resumen del Comprobante</h2>
        </header>
        <ResumeCard />
        <hr className="lineaTotal" />
        <section className="contenedorBtnTot">
          <button
            className="btnTotStylePrimary transparent"
            type="button"
            onClick={eliminarItemDelCarrito}
          >
            Cancelar
          </button>
          <SendBuy pedido={pedido} user={dataUser.id} />
        </section>
      </article>
      <ComprasAnteriores titulo="Compras Anteriores" />
    </div>
  );
};
