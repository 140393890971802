import { useState } from 'react';
import { API_DUPLICATE } from '../services/ApiRoutes';
import { API } from '../../../helpers/API';
import { useShopingCart } from './useShopingCart';

import Swal from 'sweetalert2';
import { useCliente } from '../../catalogo/hooks/useCliente';
import { useClient } from '../../catalogo/hooks/useClient';

export const useCopyCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setClient } = useClient();
  const { getClientById } = useCliente();
  const { setItem } = useShopingCart();

  const duplicateOrder = async (pedido) => {
    if (!pedido.id) return;

    setLoading(true);
    setError(null);

    try {
      const response = await API.GET(`${API_DUPLICATE}${pedido.id}`, {});

      if (response.status === 200) {
        const duplicatedItems = response.data;

        // Obtener el cliente por ID y setearlo
        const clienteData = await getClientById(pedido.id_cliente);
        if (clienteData) {
          setClient(clienteData);
        }

        duplicatedItems.forEach((item) => {
          const precios = item.precios[0];

          const articulo = {
            id: item.id,
            codigo_mg: item.codigo_mg,
            denominacion: item.denominacion,
            descripcion: item.descripcion,
            fecha_modificacion: item.fecha_modificacion,
            moneda: item.moneda,
            sinonimo: item.sinonimo,
            estado: item.estado,
            precios: item.precios,
            Stock: item.Stock,
          };

          setItem({
            ...articulo,
            cantidad: parseInt(item.cantidad),
            precios,
          });
        });

        Swal.fire({
          title: 'Pedido Repetido',
          text: 'Los items del pedido han sido agregados al carrito.',
          icon: 'success',
        });
      } else {
        throw new Error(response.detail || 'Error en la solicitud');
      }
    } catch (error) {
      setError(error.message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  return { duplicateOrder, loading, error };
};
