import React, { useContext, useEffect, useState } from 'react';
import { API_PRODUCTS_BUY } from './ApiRoutes';
import { UserContext } from '../../auth/context/UserContext';
import Swal from 'sweetalert2';
import { useShopingCart } from '../hooks/useShopingCart';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'universal-cookie';

export const SendBuy = ({ pedido }) => {
  const { dataUser } = useContext(UserContext);
  const { token } = dataUser;
  const { clearShopingCart, getTotal, getExeds } = useShopingCart();
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies(null, { path: '/' });

  //  const opDefault = cookies.get('botonCompra');

  const handleSendBuy = (comprobante) => {
    setIsLoading(true);
    cookies.set('botonCompra', comprobante);

    fetch(API_PRODUCTS_BUY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(pedido),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error al enviar la compra');
      })
      .then((data) => {
        if (data.status === 200) {
          console.log('Compra enviada exitosamente:', data);
          clearShopingCart();
          Swal.fire({
            title: 'Pedido enviado con éxito',
            text: data.message,
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Error al enviar la compra',
            text: data.errors.detail,
            icon: 'error',
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error al enviar la compra:', error);
        Swal.fire({
          title: 'Error al enviar la compra',
          text: 'Ocurrió un error al enviar la compra. Por favor, inténtalo de nuevo más tarde.',
          icon: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {/* <p>{JSON.stringify(getExeds())}</p> */}
      <button
        // className={`btnTotStyle ${opDefault === 'pedido' ? 'btnTotStylePrimary' : 'btnTotStyleSecondary'} ${precioExcedido ? 'btnTotStyleSecondary' : ''}`}
        className={`btnTotStyle ${getExeds() ? 'btnTotStyleSecondary' : 'btnTotStylePrimary'}`}
        type="button"
        onClick={() => handleSendBuy('pedido')}
        disabled={getTotal() === 0 || isLoading || getExeds()}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          // <span>{precioExcedido ? 'Precio Excedido' : 'Pedido'}</span>

          <span>{getExeds() ? 'Precio Excedido' : 'Enviar'}</span>
        )}
      </button>
      {/* <button
        className={`btnTotStyle${opDefault === 'presupuesto' ? 'Primary' : 'Secondary'}`}
        type="button"
        onClick={() => handleSendBuy('presupuesto')}
        disabled={getTotal() === 0 || isLoading || precioExcedido}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <span>{precioExcedido ? 'Precio Excedido' : 'Presupuesto'}</span>
        )}
      </button> */}
    </>
  );
};
